// https://developer.voyado.com/en/web-activity-tracking.html

import { GetCartQuery } from '@business/gql/graphql';
import config from './config';
import { CartMutationArgs } from './hooks/cart/useMutateCart';
import getContentfulLocaleFromLanguage from './locale/getContentfulLocaleFromLanguage';

interface VaProps {
  cartRef?: number;
  locale?: string;
  items?: { itemId: string; quantity: number }[];
  categoryName?: string;
  itemId?: string;
}

export function notifyVoyadoOfItemRemoval(
  partNo: string,
  previousCart: GetCartQuery['cart'],
) {
  if (!previousCart) {
    return;
  }
  const items = previousCart.items.filter((item) => item.partNo !== partNo);
  if (items.length === 0) {
    sendVoyadoEmptyCartEvent(previousCart.id);
  } else {
    sendVoyadoCartChangeEvent({ id: previousCart.id, items });
  }
}

declare let window: {
  va: (methodName: string, props: VaProps) => void;
};

export function sendVoyadoEmptyCartEvent(cartId: number) {
  if (typeof window === 'undefined') {
    return;
  }
  if (typeof window.va === 'undefined') {
    return;
  }
  window.va('emptyCart', {
    cartRef: cartId,
  });
}

interface CartProps {
  id: number;
  items: { partNo: string; quantity: number }[];
}

export function sendVoyadoCartChangeEvent(props: CartProps) {
  const items = props.items?.map((item) => ({
    itemId: item.partNo,
    quantity: item.quantity,
  }));
  const contentfulLocale = getContentfulLocaleFromLanguage(config.locale);
  if (typeof window === 'undefined') {
    return;
  }
  if (typeof window.va === 'undefined') {
    return;
  }

  window.va('cart', {
    cartRef: props.id,
    // cookie _vaI will be used as fallback for contactId
    // contactId: 'afa7625d-2e97-4667-b4c1-ad3b01194bee',
    // cartUrl: 'https://www.store.se/cart?id=354354',
    locale: contentfulLocale,
    items,
  });
}

interface ProductViewProps {
  breadcrumbLabels: string[];
  itemId: string;
}

export function sendVoyadoProductViewEvent(props: ProductViewProps) {
  try {
    if (window.va === undefined) {
      return;
    }

    const contentfulLocale = getContentfulLocaleFromLanguage(config.locale);
    const categoryName = props.breadcrumbLabels
      .filter((b) => b)
      .slice(0, -1)
      .join(' / ');
    window.va('productview', {
      categoryName,
      itemId: props.itemId,
      locale: contentfulLocale,
    });
  } catch (error) {
    console.error('Failed to send Voyado product view event: ', error);
  }
}
