const config = require('@ngg/tailwind-config/lanna');
const plugin = require('tailwindcss/plugin');
const { default: xss } = require('xss');

/** @type {import('tailwindcss').Config} */
module.exports = {
  ...config,
  content: ['./src/app/**/*.{js,jsx,ts,tsx}', './src/ui/**/*.{js,jsx,ts,tsx}'],
  theme: {
    ...config.theme,
    fontFamily: {
      sans: 'var(--font-sans)',
      serif: 'var(--font-serif)',
    },
    extend: {
      ...config.theme.extend,
      spacing: {
        ...config.theme.extend.spacing,
        full: '100%',
      },
      aspectRatio: {
        ...config.theme.extend.aspectRatio,
        portrait: '4 / 5',
        portraitDesktop: '17 / 20',
      },
      flex: {
        ...config.theme.extend.flex,
        '1/5': '0 0 20%',
        '1/4': '0 0 25%',
        '2/5': '0 0 40%',
        '4/5': '0 0 80%',
      },
      colors: {
        ...config.theme.extend.colors,
        yellow: {
          DEFAULT: '#F9B861',
          contrast: '#000000',
        },
        green: {
          DEFAULT: '#4D4F41',
          dark: '#4D4F41',
          light: '#87B88D',
          contrast: '#FFFFFF',
        },
        red: {
          DEFAULT: '#CC2B27',
          contrast: '#FFFFFF',
        },
        beige: {
          DEFAULT: '#F1EEE7',
          1: '#F1EEE7',
          2: '#E9E5DD',
          3: '#E3DFD5',
          4: '#938E81',
          contrast: '#000000',
        },
        gray: {
          DEFAULT: '#F8F7F5',
          contrast: '#000000',
        },
        airmee: {
          DEFAULT: '#000000',
          contrast: '#fff',
        },
        postnord: {
          DEFAULT: '#449ED1',
          contrast: '#000',
        },
        main: {
          DEFAULT: '#FFFFFF',
          contrast: '#000',
        },
        borderColor: {
          DEFAULT: '#E3DFD5',
        },
      },
      fontSize: {
        ...config.theme.extend.fontSize,
        xxxs: '10px',
        xxs: '11px',
        xs: ['0.75rem', 1.625],
        sm: ['0.875rem', 1.625],
        xl: '24px',
        '3xl': ['32px', '40px'],
        '4xl': ['40px', '48px'],
        '5xl': ['48px', '56px'],
        '6xl': ['56px', '64px'],
      },
      transitionDelay: {
        2000: '2000ms',
      },
      animation: {
        ...config.theme.extend.animation,
        marquee: 'marquee 25s linear infinite',
        marquee2: 'marquee2 25s linear infinite',
        'brand-banner': 'brand-banner 100s linear infinite',
      },
      keyframes: {
        marquee: {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: 'translateX(-100%)' },
        },
        marquee2: {
          '0%': { transform: 'translateX(100%)' },
          '100%': { transform: 'translateX(0%)' },
        },
        'brand-banner': {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: 'translateX(calc(-100% - 5rem))' },
        },
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            lineHeight: 1.625,
            letterSpacing: theme('letterSpacing.wide'),
            h1: {
              fontFamily: theme('fontFamily.sans'),
              fontWeight: 600,
            },
            h2: {
              fontFamily: theme('fontFamily.sans'),
            },
            h3: {
              fontFamily: theme('fontFamily.sans'),
            },
            b: {
              fontWeight: 600,
            },
          },
        },
        'info-page': {
          css: {
            // For underline style on header
            '> *:is(h1,h2,h3,h4,h5) > u': {
              minWidth: '100%',
              display: 'block',
              paddingBottom: '0.5rem',
              borderBottom: '1px solid',
              borderColor: 'var(--tw-prose-hr)',
              textDecorationLine: 'none',
            },
            b: {
              fontWeight: 400,
            },
          },
        },
      }),
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
    },
  },
  plugins: [
    ...config.plugins,
    plugin(({ theme, addBase, addVariant, addComponents }) => {
      addComponents({
        '.overflow-shadow': {
          background: `
            /* Shadow Cover TOP */
            linear-gradient(
              white 30%,
              rgba(255, 255, 255, 0)
            ) center top,
            
            /* Shadow Cover BOTTOM */
            linear-gradient(
              rgba(255, 255, 255, 0), 
              white 70%
            ) center bottom,
            
            /* Shadow TOP */
            linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.05),
              rgba(0, 0, 0, 0)
            ) center top,
            
            /* Shadow BOTTOM */
            linear-gradient(
              to top,
              rgba(0, 0, 0, 0.05),
              rgba(0, 0, 0, 0)
            ) center bottom;
            `,

          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
          backgroundAttachment: 'local, local, scroll, scroll',
        },
      });
      addVariant('not-last', '&:not(:last-child)');
      addVariant('not-first', '&:not(:first-child)');
      addVariant(
        'no-container-support',
        '@supports not (container-type: inline-size)',
      );
      addBase({
        h1: { fontFamily: theme('fontFamily.sans') },
        h2: { fontFamily: theme('fontFamily.sans') },
        h3: { fontFamily: theme('fontFamily.sans') },
        b: { fontWeight: 600 },
      });
    }),
    require('@tailwindcss/typography'),
    require('@tailwindcss/container-queries'),
    require('tailwind-scrollbar'),
  ],
};
