import Icon, { IconSizes } from '../icon/icon';
import { cn, getStockStatusText } from '@ngg/storefront-utils';

import { getStockColor } from '@utils/stockStatus/getStockColor';
import { StockStatusDictionary } from '@ts/stockStatus/StockStatusDictionary';
import { RelevantStockStatuses } from '@ts/stockStatus/RelevantStockStatuses';

export type StockStatusDetails = {
  deliveryMin?: string;
  deliveryMax?: string;
  deliveryUnit?: string;
  stockQuantity?: number | null;
  stockLimit?: number;
  isShortened?: boolean;
  nextDeliveryDate?: Date;
  locale?: string;
};
type StockStatusProps = {
  className?: string;
  iconSize?: IconSizes;
  stockStatusDictionary: StockStatusDictionary;
  status: RelevantStockStatuses | null;
  stockDetails?: StockStatusDetails;
};

const StockStatus = ({
  status,
  iconSize,
  className,
  stockStatusDictionary,
  stockDetails = {},
}: StockStatusProps) => {
  if (!status) return null;

  const statusText = getStockStatusText({
    ...stockDetails,
    stockStatus: status,
    dictionary: stockStatusDictionary,
    stockQuantity: stockDetails.stockQuantity || undefined,
  });

  return (
    <span
      className={cn(['flex items-center gap-1 font-medium italic', className])}>
      <Icon name="dot" size={iconSize ?? 6} color={getStockColor(status)} />
      {statusText}
    </span>
  );
};

export default StockStatus;
