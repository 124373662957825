import { Dictionary } from '@ts/dictionary';
import { StockStatusDictionary } from '@ts/stockStatus/StockStatusDictionary';

export const getStockStatusDictionary = (
  dictionary: Dictionary,
): StockStatusDictionary => ({
  lessThanInStock: dictionary.lessThanInStock,
  inStock: dictionary.inStock,
  inStockSoon: dictionary.inStockSoon,
  week: dictionary.week,
  preliminaryDeliveryTime: dictionary.preliminaryDeliveryTime,
  madeToOrder: dictionary.madeToOrder,
  outOfStock: dictionary.outOfStock,
  soldOut: dictionary.soldOut,
  moreThanStockLimitInStock: dictionary.moreThanStockLimitInStock,
  lessThanInStockDescription: dictionary.lessThanInStockDescription,
  onTheWayToUsBackInStock: dictionary.onTheWayToUsBackInStock,
});
