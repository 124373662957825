import { CartFragment, CartItem } from '@business/gql/graphql';
import createLoopEvent from '@lib/utils/createLoopEvent';
import getSearchEngineUserClient from '@utils/getSearchEngineUserClient';
import { gTagAddToCart } from '@utils/googleTags';
import { sendVoyadoCartChangeEvent } from '@utils/voyadoTracking';
import { useCallback, useMemo } from 'react';
import useCartAdd from './useCartAdd';
import useCartUpdate from './useCartUpdate';

export type CartMutationArgs = {
  items: (Partial<CartItem> & {
    partNo: string;
    quantity: number;
  })[];
};

export type CartMutationOptions = {
  onSuccess?: () => void;
};

function runCartTracking(
  items: {
    partNo: string;
    quantity: number;
  }[],
  cart: CartFragment,
  options?: CartMutationOptions,
) {
  try {
    gTagAddToCart(items, cart);

    sendVoyadoCartChangeEvent({
      id: cart.id,
      items: cart.items.map((item) => ({
        partNo: item.partNo,
        quantity: item.quantity,
      })),
    });

    createLoopEvent({
      payload: {
        events: items.map((item) => ({
          entity: {
            type: 'Product',
            id: item.partNo,
          },
          type: 'addtocart',
        })),
      },
      searchEngineUser: getSearchEngineUserClient(),
    });

    if (options?.onSuccess) {
      options.onSuccess();
    }
  } catch (e) {
    console.group(
      console.error(
        'Something went wrong when sending cart tracking data during add to cart.',
      ),
      console.error(e),
    );
  }
}

const useAddToCart = (locale: string) => {
  const cartAdd = useCartAdd(locale);

  const mutate = useCallback(
    (payload: CartMutationArgs, options?: CartMutationOptions) => {
      cartAdd.mutate(payload.items, {
        ...options,
        onSuccess: ({ cart }) => {
          runCartTracking(payload.items, cart, options);
        },
      });
    },
    [cartAdd],
  );

  return {
    ...cartAdd,
    mutate,
  };
};

type CartMutations = 'update' | 'add';

type AddToCartTypes = ReturnType<typeof useAddToCart>;
type CartUpdate = ReturnType<typeof useCartUpdate>;

type CartMutationHooks<T extends CartMutations> = T extends 'add'
  ? AddToCartTypes
  : CartUpdate;

export default function useMutateCart<T extends CartMutations>(
  props: { locale: string } & { type: T },
) {
  const addToCart = useAddToCart(props.locale);
  const cartUpdate = useCartUpdate(props.locale);

  return useMemo(() => {
    if (props.type === 'add') {
      return addToCart as CartMutationHooks<T> & {
        mutate: (payload: CartMutationArgs) => void;
      };
    }
    return cartUpdate as CartMutationHooks<T> & {
      mutate: (payload: CartMutationArgs) => void;
    };
  }, [addToCart, cartUpdate, props.type]);
}
