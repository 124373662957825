import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  AddToCartMutation,
  Cart,
  CartItem,
  GetCartQuery,
} from '@business/gql/graphql';
import { QueryKeys } from '@utils/queries/queryKeys';
import { getCookie, setCookie } from 'cookies-next';
import cartCookie from '@utils/cookies/cartCookie';
import { CartMutationArgs } from './useMutateCart';
import { addToCartAction } from '@actions/cart/actions';
import { MutationKeys } from '../../mutations/mutationKeys';
import { OPTIMISTIC_ADD_ITEM_TO_CART_LOADING_TIME_MS } from './config';

const OPTIMISTIC_CART_PROPS: GetCartQuery = {
  cart: {
    __typename: 'Cart',
    id: 0,
    currencyCode: '',
    totalGrossAmount: 0,
    totalVat: 0,
    totalFreight: 0,
    totalFreightInclVat: 0,
    basketPromotions: [],
    items: [],
  },
};

export default function useCartAdd(locale: string) {
  const query = useQueryClient();

  return useMutation({
    mutationKey: [MutationKeys.cart, locale],
    mutationFn: (payload: CartMutationArgs['items']) => {
      return addToCartAction({
        items: payload.map((item) => ({
          partNo: item.partNo,
          quantity: item.quantity,
        })),
        locale,
        cartId: getCookie(cartCookie)?.toString() ?? '',
      });
    },
    onMutate: async (payload) => {
      await query.cancelQueries({ queryKey: [QueryKeys.cart, locale] });
      const previousCart = query.getQueryData([
        QueryKeys.cart,
        locale,
      ]) as GetCartQuery;

      const usingPreviousCart = Boolean(
        previousCart?.cart && getCookie(cartCookie),
      );
      const optimisticCart: { cart: Cart } = usingPreviousCart
        ? JSON.parse(JSON.stringify(previousCart))
        : JSON.parse(JSON.stringify(OPTIMISTIC_CART_PROPS)); // We have to deep clone the cart to avoid reference issues when changing quantity

      payload.forEach((item) => {
        const existingItem = optimisticCart?.cart?.items.find(
          (i) => i.partNo === item.partNo,
        );
        if (existingItem) {
          existingItem.quantity += item.quantity;
        } else {
          optimisticCart.cart?.items?.push(item as CartItem);
        }
      });

      setTimeout(() => {
        // If the mutation is still ongoing after the timeout, we'll optimistically update the cart
        if (query.isMutating({ mutationKey: [MutationKeys.cart, locale] })) {
          query.setQueryData([QueryKeys.cart, locale], optimisticCart);
        }
      }, OPTIMISTIC_ADD_ITEM_TO_CART_LOADING_TIME_MS);

      return { previousCart, optimisticCart };
    },
    onSuccess: async (data) => {
      if (data.cart?.token && data.cart?.id) {
        setCookie(cartCookie, `${data.cart?.id}:${data.cart?.token}`);
      }
      query.setQueryData([QueryKeys.cart, locale], data);
    },
    onError: (_error, _variables, context) => {
      if (context?.previousCart) {
        query.setQueryData([QueryKeys.cart, locale], context.previousCart);
      }
    },
  });
}
